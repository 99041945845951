
import { getPortionFolders } from '@/modules/storm/storm.service'
import { CsSelectWithTreeview } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { PortionFolder } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import Vue from 'vue'
import { modelViewerStore } from '../../../store/modelViewer.store'

export type PortionNode = {
  label: string
  value: guid
  disabled?: boolean
  children?: PortionNode[]
}
export default Vue.extend({
  name: 'OptimizationDialogPortionSection',
  components: { CsSelectWithTreeview },
  props: {
    value: {
      type: Array as () => Array<PortionNode>,
    },
    checkFullModel: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    portions(): Array<PortionNode> {
      const portionFolders = getPortionFolders(this.modelFile).filter(
        (portionFolder) => portionFolder.isRoot
      )

      if (portionFolders.length > 0) return this.buildPortionTree(portionFolders)
      else {
        this.$emit('onCheckFullModelChange', true)
        return []
      }
    },
  },
  methods: {
    buildPortionTree(portionFolders: PortionFolder[]): PortionNode[] {
      return portionFolders.map((portionFolder): PortionNode => {
        return {
          label: portionFolder.name || '',
          value: portionFolder.id || '',
          disabled: this.checkFullModel,
          children: [
            ...this.buildPortionTree(portionFolder.portionFolders),
            ...portionFolder.portions.map((portion) => ({
              label: portion.name || '',
              value: portion.id || '',
              disabled: this.checkFullModel,
              children: [],
            })),
          ],
        }
      })
    },
  },
})
