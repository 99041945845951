var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("div", { staticClass: "mb-2" }, [
        _vm._v(_vm._s(_vm.$t("Portion selection"))),
      ]),
      _c("v-checkbox", {
        staticClass: "my-6 ml-3",
        attrs: {
          "hide-details": "",
          disabled: !_vm.portions.length,
          value: _vm.checkFullModel,
          label: _vm.$t("Run optimization on the entire model"),
        },
        on: {
          change: function ($event) {
            return _vm.$emit("onCheckFullModelChange", $event)
          },
        },
      }),
      _vm.portions?.length
        ? _c("cs-select-with-treeview", {
            staticClass: "portion-section",
            attrs: {
              items: _vm.portions,
              disabled: _vm.checkFullModel,
              draggable: "",
              value: _vm.value,
            },
            on: {
              input: function ($event) {
                return _vm.$emit("input", $event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }