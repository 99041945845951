var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-dialog",
    {
      attrs: { value: _vm.value, width: 400, persistent: "" },
      on: {
        input: function ($event) {
          return _vm.$emit("input", false)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "optimization-progress-dialog pa-6" },
        [
          _c("div", { staticClass: "text-h6 mb-4" }, [
            _vm._v(_vm._s(_vm.title)),
          ]),
          _c(
            "v-list",
            { attrs: { disabled: "" } },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { color: "primary" },
                  model: {
                    value: _vm.currentProgressIndex,
                    callback: function ($$v) {
                      _vm.currentProgressIndex = $$v
                    },
                    expression: "currentProgressIndex",
                  },
                },
                _vm._l(_vm.progresses, function (item, i) {
                  return _c(
                    "v-list-item",
                    { key: i, attrs: { disabled: _vm.isDisabled(item) } },
                    [
                      _c(
                        "v-list-item-icon",
                        [
                          _vm.isFulfilled(item)
                            ? _c(
                                "v-scroll-x-transition",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: 20, color: "success" } },
                                    [_vm._v(" mdi-check-circle ")]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.isErrorOccured(item)
                            ? _c(
                                "v-scroll-x-transition",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: 20, color: "error" } },
                                    [_vm._v(" mdi-close-circle ")]
                                  ),
                                ],
                                1
                              )
                            : _vm.isLoading(item)
                            ? _c("v-progress-circular", {
                                attrs: { indeterminate: "", size: 20 },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(item.text))])],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "v-progress-linear",
            {
              staticClass:
                "optimization-progress mt-4, progress-linear-no-transition",
              attrs: {
                height: 20,
                indeterminate:
                  !!_vm.currentProgress &&
                  _vm.isLoading(_vm.currentProgress) &&
                  _vm.currentProgress.indeterminate,
                value: _vm.currentProgress?.progress,
              },
            },
            [
              _vm._v(
                _vm._s(
                  !_vm.currentProgress || _vm.currentProgress?.indeterminate
                    ? ""
                    : _vm.currentProgress?.progress.toFixed(1) + " %"
                )
              ),
            ]
          ),
          _vm.currentProgress?.details
            ? _c("div", { staticClass: "progress-details mt-2 mb-4" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("Progress") + ": ") +
                    " " +
                    _vm._s(_vm.currentProgress?.details) +
                    " "
                ),
              ])
            : _vm._e(),
          _vm.currentProgress?.progressPortion != null
            ? _c(
                "v-progress-linear",
                {
                  staticClass: "mt-1 progress-linear-no-transition",
                  attrs: {
                    height: 20,
                    value: _vm.currentProgress.progressPortion,
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.currentProgress?.progressPortion.toFixed(1) + " %"
                    )
                  ),
                ]
              )
            : _vm._e(),
          _vm.currentProgress?.detailsPortion
            ? _c("div", { staticClass: "progress-details mt-2 mb-4" }, [
                _vm._v(" " + _vm._s(_vm.currentProgress?.detailsPortion) + " "),
              ])
            : _vm._e(),
          _vm.currentProgress?.progressSolver != null
            ? _c(
                "v-progress-linear",
                {
                  staticClass: "mt-1 progress-linear-no-transition",
                  attrs: {
                    height: 20,
                    value: _vm.currentProgress.progressSolver,
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.currentProgress?.progressSolver.toFixed(1) + " %"
                      )
                  ),
                ]
              )
            : _vm._e(),
          _vm.currentProgress?.detailsSolver
            ? _c("div", { staticClass: "progress-details mb-2 mt-2" }, [
                _vm._v(" " + _vm._s(_vm.currentProgress?.detailsSolver) + " "),
              ])
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }