
import InfoSvg from '@/assets/common/Info.svg.vue'
import { OnStockModel, OnStockProject } from '@/modules/graphql/graphql.types'
import {
  SteelspaceModel,
  getModelByIdSubscribe,
  getModelFile,
  updateModelFiles,
} from '@/modules/model'
import { getModelFileByFileIdRequest } from '@/modules/model/requests'
import {
  DefaultTolerance,
  OptimizationHubProgress,
  SectionChange,
  exchangeSections,
  fetchOnStockModelBySteelspaceId,
  getStatusItems,
  onStockStore,
  setOnStockError,
  startWarehouseOptimization,
  terminateHub,
  updateOnStockModel,
} from '@/modules/onstock'
import { getStructuralMember } from '@/modules/storm/storm.service'
import { setSupplyInstanceColors, setSupplyOptimization } from '@/modules/supply'
import {
  createWarehouseOptimization,
  getWarehouseOptimization,
  setWarehouseOptimization,
} from '@/modules/warehouse'
import {
  loadSmadsteelModelToStore,
  modelViewerStore,
  resetInstanceColor,
} from '@/store/modelViewer.store'
import { CsBtn, CsFlex, CsGrid, CsSelect, CsSimpleDialog, CsUnitField } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import Vue from 'vue'
import { OptimizationHubResult } from '../../../modules/onstock/types/OptimizationHubResult'
import DropZone from '../../DropZone.vue'
import OptimizationProgressDialog, {
  OptimizationDialogProgress,
} from '../OptimizationProgressDialog.vue'
import OptimizationDialogPortionSection, {
  PortionNode,
} from './OptimizationDialogPortionSection.vue'
import WarehouseOptimizationDialogPopUp from './WarehouseOptimizationDialogPopUp.vue'
import WarehouseOptimizationDialogRulesManager from './WarehouseOptimizationDialogRulesManager.vue'

export enum WarehouseOptimizationDialogPopUpRefs {
  Priority = 'WarehouseOptimizationDialogPopUpPriority',
  WarehouseOptimizationMode = 'WarehouseOptimizationDialogPopUpWarehouseOptimizationMode',
  Tolerance = 'WarehouseOptimizationDialogPopUpTolerance',
  Match = 'WarehouseOptimizationDialogPopUpMatch',
}

interface WarehouseOptimizationDialogProgresses {
  initialization: OptimizationDialogProgress
  dataLoading: OptimizationDialogProgress
  groupBy: OptimizationDialogProgress
  warehouseOptimization: OptimizationDialogProgress
  sectionExchange: OptimizationDialogProgress
}

export default Vue.extend({
  name: 'WarehouseOptimizationDialog',
  components: {
    CsSimpleDialog,
    CsBtn,
    CsUnitField,
    CsSelect,
    OptimizationProgressDialog,
    WarehouseOptimizationDialogRulesManager,
    OptimizationDialogPortionSection,
    WarehouseOptimizationDialogPopUp,
    DropZone,
    CsFlex,
    InfoSvg,
    CsGrid,
  },
  props: {
    value: { type: Boolean, default: false },
    error: String,
    maxWidth: { type: Number, default: 630 },
  },
  data() {
    return {
      selectedPortions: [] as PortionNode[],
      checkFullModel: false,
      WarehouseOptimizationDialogPopUpRefs,
      priorityItems: [
        { text: this.$t('Length'), value: 0 },
        { text: this.$t('Date'), value: 1 },
        { text: this.$t('Weight'), value: 2 },
      ],
      priorityOrders: [
        { text: this.$t('Ascending'), value: 0 },
        { text: this.$t('Descending'), value: 1 },
      ],
      methodItems: [
        { text: this.$t('Approximating'), value: 0 },
        { text: this.$t('Exact'), value: 1 },
      ],
      files: [] as File[],
      tolerance: DefaultTolerance,
      valid: false,
      loading: false,
      mainPriority: 0,
      secondaryPriority: 0,
      mainPriorityOrder: 0,
      secondaryPriorityOrder: 0,
      method: 0,
      preferExactMatchesChecked: false,
      warehouseOptimizationProgressDialog: false,
      warehouseOptimizationDialogPopUp: false,
      currentWarehouseOptimizationStageIndex: 0,
      warehouseOptimizationProgresses: {
        initialization: {
          value: 0,
          text: this.$tc('Initialization'),
          details: '',
          indeterminate: true,
          progress: 0,
        },
        dataLoading: {
          value: 1,
          text: this.$tc('Parsing excel file'),
          details: '',
          progress: 0,
        },
        groupBy: {
          value: 2,
          text: this.$tc('Grouping warehouse items'),
          progress: 0,
          indeterminate: true,
          details: '',
        },
        warehouseOptimization: {
          value: 3,
          text: this.$tc('Warehouse optimization'),
          progress: 0,
          details: '',
        },
        sectionExchange: {
          value: 4,
          text: this.$tc('Replacing model components'),
          progress: 0,
          indeterminate: true,
          details: '',
          error: false,
        },
      } as WarehouseOptimizationDialogProgresses,
      step: 1,
      managerRuleIds: [] as number[],
    }
  },
  watch: {
    warehouseOptimizationProgressDialog() {
      Object.keys(this.warehouseOptimizationProgresses).forEach((key) => {
        this.warehouseOptimizationProgresses[key] = {
          ...this.warehouseOptimizationProgresses[key],
          progress: 0,
          details: '',
        }
      })
      this.warehouseOptimizationProgresses.sectionExchange.error = false
      this.currentWarehouseOptimizationStageIndex = 0
    },
  },
  computed: {
    lastStep(): number {
      return 4
    },
    model(): SteelspaceModel | null {
      return getModelByIdSubscribe()
    },
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    onstockProject(): OnStockProject | null {
      return onStockStore.project
    },
    showDialog: {
      get(): boolean {
        return this.value
      },
      set(newVal: boolean): void {
        this.resetStates()
        this.$emit('input', newVal)
      },
    },
    priorizationRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Priority is required')

          return true
        },
      ]
    },
    priorityOrderRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Ordering is required')

          return true
        },
      ]
    },
    toleranceRules(): ((value: any) => true | string)[] {
      return [
        (value) => {
          if (value == null) return this.$tc('Tolerance is required.')

          if (value < 0) return this.$tc('Tolerance cannot be a negative number.')

          return true
        },
      ]
    },
    getUploadedFile(): File | undefined {
      return this.files[0]
    },
    getWarehouseOptimizationProgresses(): OptimizationDialogProgress[] {
      return Object.keys(this.warehouseOptimizationProgresses).map(
        (key) => this.warehouseOptimizationProgresses[key]
      )
    },
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
  },
  methods: {
    async getSectionChanges(): Promise<SectionChange[]> {
      const warehouseOptimization = await getWarehouseOptimization()

      if (!warehouseOptimization) return []

      const changedObjectMatches = warehouseOptimization.warehouseObjectMatches.filter(
        (objectMatch) => {
          const structuralMember = getStructuralMember(this.modelFile, objectMatch.smadsteelId)

          if (!objectMatch.warehouseObject.section || !objectMatch.warehouseObject.material)
            return false

          if (
            structuralMember?.section?.name === objectMatch.warehouseObject.section.fullName &&
            structuralMember?.material?.name === objectMatch.warehouseObject.material.fullName
          )
            return false

          console.info(
            `Section changed from [Section: ${structuralMember?.section?.name}, Material: ${structuralMember?.material?.name}] to [Section: ${objectMatch.warehouseObject.section.fullName}, Material: ${objectMatch.warehouseObject.material.fullName}]`
          )
          return true
        }
      )

      return changedObjectMatches.map((objectMatch) => {
        return {
          structuralMemberGUID: objectMatch.smadsteelId,
          sectionName: objectMatch.warehouseObject.section?.fullName,
          sectionMaterial: objectMatch.warehouseObject.material?.fullName,
        } as SectionChange
      })
    },
    handleInfoBtnClick(scrollToRef: string): void {
      this.warehouseOptimizationDialogPopUp = true
      ;(
        this.$refs.warehouseOptimizationDialogPopUp as InstanceType<
          typeof WarehouseOptimizationDialogPopUp
        >
      )?.scrollToRef(scrollToRef)
    },
    closeOrOpenWarehouseOptimizationDialog(value: boolean): void {
      this.$emit('input', value)
    },
    managerRuleTableItemsUpdated(managerRuleIds: number[]): void {
      this.managerRuleIds = managerRuleIds
    },
    stepBackBtnClicked(): void {
      if (this.step > 1) {
        this.step -= 1
      } else {
        this.$emit('input', false)
      }
    },
    stepForwardBtnClicked(): void {
      const isValid = (this.$refs.warehouseOptimizationForm as HTMLFormElement)?.validate()
      if (!isValid) return

      this.step = this.step < this.lastStep ? this.step + 1 : this.lastStep
    },
    async handleProgressResult(result: OptimizationHubResult): Promise<void> {
      if (result.stage == null) return
      else if (result.error) {
        setOnStockError('Progress result error: ' + result.error)
        this.terminateProgressWindow()
        await terminateHub()
        return
      }

      const relevantProgressKey = Object.keys(this.warehouseOptimizationProgresses).find(
        (progressKey) => this.warehouseOptimizationProgresses[progressKey].value === result.stage
      )

      if (!relevantProgressKey) {
        console.warn('Relevant progress key not found')
        return
      }

      this.warehouseOptimizationProgresses[relevantProgressKey] = {
        ...this.warehouseOptimizationProgresses[relevantProgressKey],
        progress: 100,
      }
    },
    async handleProgress(progress: OptimizationHubProgress): Promise<void> {
      if (this.currentWarehouseOptimizationStageIndex != progress.currentStageNumber)
        this.currentWarehouseOptimizationStageIndex = progress.currentStageNumber

      const relevantProgressKey = Object.keys(this.warehouseOptimizationProgresses).find(
        (progressKey) =>
          this.warehouseOptimizationProgresses[progressKey].value === progress.currentStageNumber
      )

      if (!relevantProgressKey) {
        console.warn('Relevant progress key not found')
        return
      }

      this.warehouseOptimizationProgresses[relevantProgressKey] = {
        ...this.warehouseOptimizationProgresses[relevantProgressKey],
        details: progress.stageProgressDetails,
        detailsPortion: progress.portionProgressDetails,
        detailsSolver: progress.solverProgressDetails,

        progress: (progress.stageProgressCurrent / progress.stageProgressMax) * 100,
        progressSolver:
          progress.solverProgressCurrent != null
            ? (progress.solverProgressCurrent / progress.solverProgressMax) * 100
            : null,
        progressPortion:
          progress.portionProgressCurrent != null
            ? (progress.portionProgressCurrent / progress.portionProgressMax) * 100
            : null,
      } as WarehouseOptimizationDialogProgresses
    },
    async submit(): Promise<void> {
      const isValid = (this.$refs.warehouseOptimizationForm as HTMLFormElement)?.validate()

      if (
        !isValid ||
        !this.model ||
        !this.onstockModel ||
        !this.onstockProject ||
        !this.getUploadedFile
      )
        return

      this.warehouseOptimizationProgressDialog = true
      this.loading = true

      const modelFile = await getModelFile(
        this.model.id,
        this.model.lastModificationDate ?? Date.now()
      )

      if (modelFile) {
        const warehouseOptimization = await createWarehouseOptimization(
          this.onstockProject.id.toString(),
          this.onstockModel.id.toString(),
          this.getUploadedFile,
          new File([modelFile], 'test.smadsteel'),
          this.tolerance,
          this.method,
          this.mainPriority,
          this.secondaryPriority,
          this.mainPriorityOrder,
          this.secondaryPriorityOrder,
          this.managerRuleIds,
          this.preferExactMatchesChecked,
          this.checkFullModel
            ? []
            : this.selectedPortions.map((portion) => ({
                smadsteelId: portion.value,
                name: portion.label,
              }))
        )

        this.currentWarehouseOptimizationStageIndex = 1
        this.warehouseOptimizationProgresses.initialization.progress = 100

        if (!warehouseOptimization) {
          setOnStockError(`Warehouse optimization doesn't exist`)
          this.terminateProgressWindow()
          await terminateHub()
          return
        }
        await startWarehouseOptimization(
          warehouseOptimization.id,
          this.handleProgress,
          this.handleProgressResult
        )
      }

      this.currentWarehouseOptimizationStageIndex++

      if (this.model) {
        const modelCsmFile = this.model.relatedFiles.find((file) => file.extension === 'csm')

        if (modelCsmFile) {
          const downloadedCsmFile = await getModelFileByFileIdRequest(
            this.model.id,
            modelCsmFile.id
          )

          if (downloadedCsmFile) {
            const sectionChanges = await this.getSectionChanges()
            if (sectionChanges.length > 0) {
              const changedModelFiles = await exchangeSections(
                this.onstockModel.id.toString(),
                new File([downloadedCsmFile.file], 'test.csm'),
                sectionChanges,
                [this.model.name + '.csm', this.model.name + '.smadsteel']
              )

              if (changedModelFiles) {
                const modelFiles: File[] = changedModelFiles.map((modelFile) => {
                  return new File([modelFile.file], modelFile.fileName)
                })

                const updatedFiles = await updateModelFiles(
                  this.model.id,
                  this.model.checksum,
                  this.model.name,
                  this.model.description,
                  this.model.tags,
                  modelFiles
                )

                if (onStockStore.onStockModel && updatedFiles) {
                  await updateOnStockModel(
                    onStockStore.onStockModel.projectId.toString(),
                    onStockStore.onStockModel.id.toString(),
                    getStatusItems()[onStockStore.onStockModel.status].value,
                    updatedFiles.model.checksum
                  )

                  const modelFile = await getModelFile(
                    updatedFiles.model.id,
                    updatedFiles.model.lastModificationDate ?? Date.now()
                  )

                  if (modelFile) {
                    await loadSmadsteelModelToStore(modelFile)
                    this.warehouseOptimizationProgresses.sectionExchange.progress = 100
                  } else console.warn('Model file not loaded to store!')
                } else {
                  this.warehouseOptimizationProgresses.sectionExchange.error = true
                  console.warn('Error occured during model file upload!')
                }
              } else {
                this.warehouseOptimizationProgresses.sectionExchange.error = true
                console.warn('Changed model files not found!')
              }
            } else console.info('There is no section to change!')
          } else console.warn('Model file download failed!')
        } else console.warn('Model .csm file not found!')
      }

      this.warehouseOptimizationProgressDialog = false

      await fetchOnStockModelBySteelspaceId(this.$route.params.modelId) //to update state
      const newWarehouseOptimization = await getWarehouseOptimization() //to update state

      if (newWarehouseOptimization) {
        setWarehouseOptimization(newWarehouseOptimization)

        // reset supply opt if new warehouse opt created
        setSupplyOptimization(null)
        setSupplyInstanceColors([])
        resetInstanceColor()
      }

      this.terminateProgressWindow()
    },
    dialogClose(): void {
      this.preferExactMatchesChecked = false
      this.tolerance = DefaultTolerance
      this.dropUploadedFile()
    },
    dropUploadedFile(): void {
      const dropZone = this.$refs.dropZoneRef as any
      dropZone?.emptyFiles()
    },
    resetStates() {
      this.files = []
      this.selectedPortions = []
      this.checkFullModel = false
      this.valid = false
      this.loading = false
      this.mainPriority = 0
      this.secondaryPriority = 0
      this.mainPriorityOrder = 0
      this.secondaryPriorityOrder = 0
      this.method = 0
      this.warehouseOptimizationProgressDialog = false
      this.managerRuleIds = [] as number[]
      this.step = 1
      ;(
        this.$refs.warehouseOptimizationDialogRulesManager as InstanceType<
          typeof WarehouseOptimizationDialogRulesManager
        >
      )?.resetManagerRuleTableItems()
      ;(this.$refs.warehouseOptimizationForm as HTMLFormElement)?.reset()
    },
    terminateProgressWindow(): void {
      this.dialogClose()
      this.resetStates()
      this.showDialog = false
    },
  },
})
