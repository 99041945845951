
import { commentStore } from '@/modules/comment'
import { getModelByIdSubscribe, SteelspaceModel } from '@/modules/model'
import { CsCommentBubble } from '@consteel/csuetify'
import Vue from 'vue'
import {
  addItemsToSelection,
  modelViewerStore,
  moveCameraToComment,
  removeSelection,
  setDipslaySettings,
  setSelectedCommentIds,
} from '../../../store/modelViewer.store'
import { FloatingComment } from '../../../types'

export default Vue.extend({
  name: 'FloatingComment',
  components: {
    CsCommentBubble,
  },
  props: {
    floatingComment: {
      type: Object as () => FloatingComment,
    },
    opacity: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    async onClickFloatingComment(): Promise<void> {
      if (this.isCommentSelected) {
        removeSelection()
        setSelectedCommentIds([])
      } else {
        setSelectedCommentIds(this.floatingComment.commentIds)
        removeSelection()
        addItemsToSelection(...this.floatingComment.smadsteelIds)
        const selectedCommentId = this.floatingComment.commentIds[0] //select the first elemnt of comments
        moveCameraToComment(selectedCommentId)
        setDipslaySettings(selectedCommentId)
      }
    },
  },
  computed: {
    isFloatingCommentVisible(): boolean {
      if (!modelViewerStore.commentVisibility) return false
      return (
        !modelViewerStore.filterUnreadComments ||
        (modelViewerStore.filterUnreadComments && this.numberOfUnreadComments > 0)
      )
    },
    numberOfUnreadComments(): number {
      let unreadComments = 0
      this.floatingComment.commentIds.map((commentId) => {
        const isCommentUnreadByUser = commentStore.unreads.find(
          (unread) => unread.commentId === commentId
        )
        if (isCommentUnreadByUser) unreadComments++
      })
      return unreadComments
    },
    commentIcon(): string {
      return this.floatingComment.commentIds.length > 1 ? 'ChatIconMultiple' : 'ChatIcon'
    },
    isCommentSelected(): boolean {
      return this.floatingComment.commentIds.every((commentId) =>
        modelViewerStore.selectedCommentIds.includes(commentId)
      )
    },
    modelId(): string {
      return this.$route.params.modelId
    },
    model(): SteelspaceModel | null {
      return getModelByIdSubscribe(this.modelId)
    },
    commentScale(): number {
      return modelViewerStore.scaleOfComments
    },
  },
})
