var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "comment-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        justify: "flex-start",
        vertical: "",
      },
    },
    [
      _vm.commentLoading
        ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("span", { staticClass: "comment-tab-title pa-5 pr-2" }, [
            _vm._v(_vm._s(_vm.$t("Comments")) + " "),
          ]),
          _c("div", { staticClass: "model-number" }, [
            _vm._v(_vm._s(_vm.modelNumber)),
          ]),
          _c("comment-type-select"),
          _vm.numberOfUnreadComments > 0
            ? _c("div", { staticClass: "comment-tab-unread-badge" })
            : _vm._e(),
        ],
        1
      ),
      _c("v-divider"),
      !_vm.commentsTree?.length
        ? _c("cs-empty-comment", {
            staticClass: "empty-comment",
            attrs: { isObjectSpecific: _vm.isObjectSpecificComments },
          })
        : _vm._e(),
      _vm._l(_vm.commentsTree, function (comment) {
        return _c(
          "div",
          { key: comment.id },
          [
            _c("cs-comment-tree", {
              attrs: {
                email: _vm.getCurrentUser.email,
                item: comment,
                replyCnt: comment.replyCnt,
                disabled: _vm.commentLoading,
                selected: _vm.selectedCommentId === comment.id,
                isUnreadMessage: _vm.isCommentUnread(comment.id),
              },
              on: {
                saveBtnClicked: function ($event) {
                  return _vm.handleCommentSaveBtnClick(comment.id, ...arguments)
                },
                deleteItemClicked: function ($event) {
                  return _vm.handleCommentDeleteActionBtnClicked(
                    comment.id,
                    ...arguments
                  )
                },
                commentSendBtnClicked: function ($event) {
                  return _vm.handleCommentReplySendBtnClick(
                    comment.id,
                    ...arguments
                  )
                },
                commentTreeSelectorClicked: function ($event) {
                  return _vm.handleCommentTreeSelectorClick(
                    comment.id,
                    ...arguments
                  )
                },
                rootCommentClicked: _vm.handleRootCommentClick,
              },
            }),
          ],
          1
        )
      }),
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          width: "500",
          submitText: _vm.$t("Delete"),
          description: _vm.$t("This action is irreversible."),
          title: _vm.$t("Are you sure you want to delete the comment?"),
        },
        on: { submit: _vm.handleCommentDeleteBtnClicked },
        model: {
          value: _vm.showDeleteCommentDialog,
          callback: function ($$v) {
            _vm.showDeleteCommentDialog = $$v
          },
          expression: "showDeleteCommentDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }