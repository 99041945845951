
import {
  addItemsToSelection,
  modelViewerStore,
  removeItemsFromSelection,
} from '@/store/modelViewer.store'
import {
  CsFlex,
  CsRadio,
  CsRadioGroup,
  CsSelectTree,
  CompareStates,
  TreeNode,
} from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { CacheContainer, GetAll, StructuralMember } from '@consteel/storm'
import Vue from 'vue'

enum Filter {
  Section = 0,
  Material = 1,
}

class ParsedStrucutralMember {
  structuralMember?: StructuralMember
  compareStates?: CompareStates
}

export default Vue.extend({
  name: 'FilterTab',
  components: {
    CsFlex,
    CsSelectTree,
    CsRadio,
    CsRadioGroup,
  },
  data() {
    return {
      cache: new CacheContainer(),
      secondaryCache: new CacheContainer(),
      filter: Filter.Section,
    }
  },
  computed: {
    model(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    secondaryModel(): Model | undefined | null {
      return modelViewerStore.secondaryModel.rawSmadsteelModel
    },
    items(): TreeNode[] {
      if (!this.model) {
        return []
      }
      const res = this.buildStructuralMemberTree()
      return res
    },
    structuralMembers(): StructuralMember[] {
      if (!this.model) return []
      console.time('Get Members')
      const members = GetAll<StructuralMember>(this.model, StructuralMember, undefined, this.cache)
      console.timeEnd('Get Members')

      return members
    },
    structuralMembersSecondary(): StructuralMember[] {
      if (!modelViewerStore.secondaryModel.rawSmadsteelModel) return []
      console.time('Get Members')
      const members = GetAll<StructuralMember>(
        modelViewerStore.secondaryModel.rawSmadsteelModel,
        StructuralMember,
        undefined,
        this.secondaryCache
      )
      console.timeEnd('Get Members')

      return members
    },
    selectedIds(): string[] {
      return modelViewerStore.selectedIds
    },
  },
  watch: {
    model(): void {
      this.cache.Clear()
    },
    secondaryModel(): void {
      this.secondaryCache.Clear()
    },
  },
  methods: {
    sortParsedMembersByName(members: ParsedStrucutralMember[]): ParsedStrucutralMember[] {
      return members.sort((a, b) =>
        (a.structuralMember?.name || '') > (b.structuralMember?.name || '')
          ? 1
          : (b.structuralMember?.name || '') > (a.structuralMember?.name || '')
          ? -1
          : 0
      )
    },
    parseStructuralMembers(): ParsedStrucutralMember[] {
      let parsedItems = [] as ParsedStrucutralMember[]
      parsedItems = this.structuralMembers.map((i) => {
        const changed = !!(i.id && modelViewerStore.changedItems?.includes(i.id))
        const deleted = !!(i.id && modelViewerStore.deletedItems?.includes(i.id))
        let parsedItem = new ParsedStrucutralMember()
        // eslint-disable-next-line no-undef
        parsedItem.structuralMember = structuredClone(i)

        if ((changed || deleted) && parsedItem.structuralMember) {
          parsedItem.structuralMember.name = `${parsedItem.structuralMember.name} ${modelViewerStore.model.modelNumber}`
        }

        parsedItem.compareStates = {
          added: false,
          changed,
          deleted,
        }

        return parsedItem
      })

      this.structuralMembersSecondary.forEach((i) => {
        const changed = !!(i.id && modelViewerStore.changedItems?.includes(i.id))
        const added = !!(i.id && modelViewerStore.addedItems?.includes(i.id))
        if (changed || added) {
          let parsedItem = new ParsedStrucutralMember()
          // eslint-disable-next-line no-undef
          parsedItem.structuralMember = structuredClone(i)
          parsedItem.compareStates = {
            added,
            changed,
            deleted: false,
          }

          if (parsedItem.structuralMember)
            parsedItem.structuralMember.name = `${parsedItem.structuralMember.name} ${modelViewerStore.secondaryModel.modelNumber}`

          parsedItems.push(parsedItem)
        }
      })

      return this.sortParsedMembersByName(parsedItems)
    },
    buildStructuralMemberTree(): TreeNode[] {
      console.time('Build tree')
      const result: TreeNode[] = []

      const parsedStructuralMembers = this.parseStructuralMembers()
      if (parsedStructuralMembers.length === 0) return result

      let sectionMap = new Map<string | undefined, TreeNode>()
      for (const member of parsedStructuralMembers) {
        if (this.filter === Filter.Material) {
          const leaf: TreeNode | undefined = sectionMap.get(member?.structuralMember?.material?.id)
          const child: TreeNode = {
            id: member.structuralMember?.id ?? 'Other',
            name: member.structuralMember?.name ?? 'N/A',
            open: false,
            compareStates: member.compareStates,
            children: [],
          }
          if (!leaf) {
            const rootLeaf = {
              id: member?.structuralMember?.material?.id ?? 'Other',
              name: member?.structuralMember?.material?.name ?? 'N/A',
              children: [child],
              compareStates: member.compareStates,
            }
            sectionMap.set(member?.structuralMember?.material?.id, rootLeaf)
          } else {
            leaf.children?.push(child)
            leaf.compareStates = {
              added: !!(leaf.compareStates?.added || child.compareStates?.added),
              changed: !!(leaf.compareStates?.changed || child.compareStates?.changed),
              deleted: !!(leaf.compareStates?.deleted || child.compareStates?.deleted),
            }
          }
        } else if (this.filter === Filter.Section) {
          const leaf: TreeNode | undefined = sectionMap.get(member.structuralMember?.section?.id)
          const child: TreeNode = {
            id: member.structuralMember?.id ?? 'Other',
            name: member.structuralMember?.name ?? 'N/A',
            open: false,
            compareStates: member.compareStates,
            children: [],
          }

          if (!leaf) {
            const rootLeaf = {
              id: member.structuralMember?.section?.id ?? 'Other',
              name: member.structuralMember?.section?.name ?? 'N/A',
              ...(member instanceof ParsedStrucutralMember && member.compareStates
                ? { compareStates: member.compareStates }
                : {}),
              children: [child],
            }
            sectionMap.set(member.structuralMember?.section?.id, rootLeaf)
          } else {
            leaf.children?.push(child)
            leaf.compareStates = {
              added: !!(leaf.compareStates?.added || child.compareStates?.added),
              changed: !!(leaf.compareStates?.changed || child.compareStates?.changed),
              deleted: !!(leaf.compareStates?.deleted || child.compareStates?.deleted),
            }
          }
        }
      }

      const placeholderSections = [...sectionMap.entries()].filter((entry) =>
        entry[1].name.includes('Placeholder')
      )
      const normalSections = [...sectionMap.entries()].filter(
        (entry) =>
          placeholderSections.find((placeholderEntry) => placeholderEntry[1].id == entry[1].id) ==
          null
      )

      normalSections.sort((a, b) => (a[1].name > b[1].name ? 1 : -1))
      placeholderSections.sort((a, b) => (a[1].name > b[1].name ? 1 : -1))

      sectionMap = new Map([...normalSections, ...placeholderSections])

      sectionMap.forEach((value) => {
        result.push(value)
      })

      console.timeEnd('Build tree')
      return result
    },
    select: addItemsToSelection,
    remove: removeItemsFromSelection,
  },
})
