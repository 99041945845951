
import Vue from 'vue'
import InfoSvg from '@/assets/common/Info.svg.vue'
import ManagerRuleEditorDialog from '../ManagerRulesDialog/ManagerRuleEditorDialog.vue'
import { CsFlex, CsBtn, CsSelect } from '@consteel/csuetify'
import { DataTableHeader } from 'vuetify'
import {
  chainParameterNames,
  getEngineerRuleConditionText,
  onStockStore,
  OpenOptions,
  setDefaultManagerRuleDialogDataByRuleId,
} from '@/modules/onstock'
import { DefaultManagerRuleData } from '../../../modules/onstock/types/OnStockManagerRuleInterfaces'
import ManagerRulesSummaryDialog from '../ManagerRulesDialog/ManagerRulesSummaryDialog.vue'
import { EngineerRule } from '@/modules/graphql/graphql.types'

type EngineerSetting = {
  id: number
  portion: string
  condition: string
}

type ManagerSetting = {
  id: number | null
  rule: string
  parameter: string
}

type ManagerRuleSelector = {
  id: number
  rule: string
  parameter: string
  disabled: boolean
}

export default Vue.extend({
  name: 'WarehouseOptimizationDialogRulesManager',
  components: {
    CsFlex,
    InfoSvg,
    CsBtn,
    CsSelect,
    ManagerRuleEditorDialog,
    ManagerRulesSummaryDialog,
  },
  props: {
    preferExactMatchesChecked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      managerRuleTableItems: [] as ManagerSetting[],
      engineerRuleTableItems: [] as EngineerSetting[],
      showManagerRuleEditorDialog: false,
      showManagerRuleSummaryDialog: false,
      defaultManagerRuleDialogData: {
        openOption: OpenOptions.Write,
        managerRuleId: '',
        dialogName: '',
        selectedSectionFamily: { text: '', id: '' },
        parameterSettings: [],
        sectionTableData: [],
      } as DefaultManagerRuleData,
    }
  },
  mounted() {
    this.engineerRuleTableItems = this.getAllEngineerRules
  },
  computed: {
    engineerRules(): EngineerRule[] | undefined {
      return onStockStore.onStockModel?.engineerRules
    },
    getAllManagerRuleIds(): number[] {
      let allManagerRuleIds: number[] = []
      this.managerRuleTableItems.forEach((managerRule) => {
        if (managerRule.id !== null) allManagerRuleIds.push(managerRule.id)
      })
      return allManagerRuleIds
    },
    getAllEngineerRules(): EngineerSetting[] {
      if (!this.engineerRules) return []

      return this.engineerRules.map((engineerRule) => {
        return {
          id: engineerRule.id,
          portion: engineerRule.portionName,
          condition: getEngineerRuleConditionText(engineerRule.condition),
        } as EngineerSetting
      })
    },
    isAllManagerRulesSelected(): boolean {
      const isAllRulesSelected = this.managerRuleTableItems.every((rule) => {
        if (rule.id === null) return false
        return true
      })
      return (
        onStockStore.onStockModel?.managerRules.length === this.managerRuleTableItems.length &&
        isAllRulesSelected
      )
    },
    managerRuleHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('Rule').toString(),
          align: 'start',
          sortable: false,
          value: 'rule',
          width: '40%',
        },
        {
          text: this.$t('Parameter').toString(),
          value: 'parameter',
          sortable: false,
          width: '40%',
        },
        {
          text: this.$t('Actions').toString(),
          value: 'actions',
          sortable: false,
          width: '20%',
        },
      ]
    },
    engineerRuleHeaders(): DataTableHeader[] {
      return [
        {
          text: this.$t('Portion').toString(),
          align: 'start',
          sortable: false,
          value: 'portion',
          width: '40%',
        },
        {
          text: this.$t('Condition').toString(),
          value: 'condition',
          sortable: false,
          width: '60%',
        },
      ]
    },
  },
  methods: {
    handleInfoBtnClick(): void {
      this.$emit('infoBtnClicked')
    },
    resetManagerRuleTableItems(): void {
      this.managerRuleTableItems = []
    },
    getManagerRuleSelectorItems(item: ManagerSetting): ManagerRuleSelector[] {
      let managerRuleSelectorData = [] as ManagerRuleSelector[]
      onStockStore.onStockModel?.managerRules.forEach((rule) => {
        managerRuleSelectorData.push({
          id: rule.id,
          rule: rule.name,
          parameter: chainParameterNames(rule.managerRuleParameters, rule.materialStep),
          disabled:
            !!this.managerRuleTableItems.find((tableItem) => tableItem.id === rule.id) &&
            item.id !== rule.id,
        })
      })
      return managerRuleSelectorData
    },
    async handleClickCreateNewRule(): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.Write
      )
      this.showManagerRuleEditorDialog = true
    },
    async handleClickOpenRuleAsReadOnly(itemId: number): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.ReadOnly,
        itemId
      )
      this.showManagerRuleEditorDialog = true
    },
    async handleClickEditRule(itemId: number): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.Edit,
        itemId
      )
      this.showManagerRuleEditorDialog = true
    },
    handleGetAllManagerRulesBtnClick(): void {
      this.showManagerRuleSummaryDialog = true
    },
    handleClickDeleteRule(itemId: number): void {
      this.managerRuleTableItems = this.managerRuleTableItems.filter((rule) => rule.id !== itemId)
    },
    handleAddRuleBtnClick(): void {
      const isDummyRuleCreatedBefore = this.managerRuleTableItems.find(
        (ruleItem) => ruleItem.rule === ''
      )
      if (!isDummyRuleCreatedBefore) {
        this.managerRuleTableItems = [
          ...this.managerRuleTableItems,
          { id: null, rule: '', parameter: '' } as ManagerSetting,
        ] // add dummy element
      }
    },
    ruleSelectorChanged(data: ManagerSetting): void {
      const managerRuleData = onStockStore.onStockModel?.managerRules.find(
        (rule) => rule.name === data.rule
      )

      if (!managerRuleData) return

      this.managerRuleTableItems = this.managerRuleTableItems.map((rule) => {
        if (rule.rule === data.rule)
          return {
            id: managerRuleData.id,
            rule: managerRuleData.name,
            parameter: chainParameterNames(
              managerRuleData.managerRuleParameters,
              managerRuleData.materialStep
            ),
          } as ManagerSetting
        return rule
      })
    },
  },
  watch: {
    managerRuleTableItems(): void {
      this.$emit('managerRuleTableItemsUpdated', this.getAllManagerRuleIds)
    },
    showManagerRuleEditorDialog(): void {
      this.$emit('closeOrOpenWarehouseOptimizationDialog', !this.showManagerRuleEditorDialog)
    },
    showManagerRuleSummaryDialog(): void {
      this.$emit('closeOrOpenWarehouseOptimizationDialog', !this.showManagerRuleSummaryDialog)
    },
    engineerRules(): void {
      this.engineerRuleTableItems = this.getAllEngineerRules
    },
  },
})
