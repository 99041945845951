
import Vue from 'vue'
import { CsTextField, CsFlex } from '@consteel/csuetify'

export default Vue.extend({
  name: 'DropZone',
  components: {
    CsTextField,
    CsFlex,
  },
  props: {
    value: {
      type: Array as () => Array<File>,
      default: () => [],
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isDragging: false,
      fileNotSupported: false,
      binaryString: '',
      loading: false,
    }
  },
  methods: {
    onChange(): void {
      const _fileInput = this.$refs.fileInput as HTMLInputElement
      const _file = _fileInput?.files?.[0]

      if (_file) {
        if (_file.name.endsWith('.xls') || _file.name.endsWith('.xlsx')) {
          this.loading = true
          this.fileNotSupported = false
          this.$emit('input', [_file])
          this.loading = false
        } else {
          this.fileNotSupported = true
        }
      }
    },
    dragover(e): void {
      e.preventDefault()
      this.fileNotSupported = false
      this.isDragging = true
    },
    dragleave(): void {
      this.isDragging = false
    },
    drop(e): void {
      e.preventDefault()
      let _filesRef = this.$refs.fileInput as HTMLInputElement

      if (_filesRef) {
        _filesRef.files = e.dataTransfer.files
        this.onChange()
      }

      this.isDragging = false
    },
    emptyFiles(): void {
      ;(this.$refs.fileInput as HTMLInputElement).form?.reset()
      ;(this.$refs.fileInput as HTMLInputElement).files = null
      this.$emit('input', [])
    },
  },
  computed: {
    rules() {
      return [
        (value) => {
          if (!this.required) return true
          if (!value) return this.$tc('Uploading a file is required.')

          return true
        },
      ]
    },
    fileUploaded(): boolean {
      return !!this.value.length
    },
    idleState(): boolean {
      if (!this.isDragging && !this.fileUploaded && !this.fileNotSupported) {
        return true
      }
      return false
    },
    fileName(): string | undefined {
      return this.value?.[0]?.name
    },
  },
})
