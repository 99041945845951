var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("cs-flex", { attrs: { vertical: "" } }, [
    _c("div", { staticClass: "drop-zone" }, [
      _c(
        "div",
        {
          staticClass: "dropzone-container pa-3 mb-2",
          on: {
            dragover: _vm.dragover,
            dragleave: _vm.dragleave,
            drop: _vm.drop,
          },
        },
        [
          _c("input", {
            ref: "fileInput",
            staticClass: "hidden-input",
            attrs: {
              type: "file",
              name: "file",
              id: "fileInput",
              accept: ".xls, .xlsx",
            },
            on: { change: _vm.onChange },
          }),
          _c(
            "label",
            { staticClass: "file-label", attrs: { for: "fileInput" } },
            [
              _vm.isDragging
                ? _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v("mdi-file-upload-outline")]),
                      _c("p", { staticClass: "dropzone-message" }, [
                        _vm._v(_vm._s(_vm.$t("Drop the file for upload"))),
                      ]),
                    ],
                    1
                  )
                : !_vm.fileNotSupported
                ? _c(
                    "div",
                    [
                      _c("v-icon", [
                        _vm._v(
                          _vm._s(
                            _vm.fileUploaded
                              ? "mdi-check"
                              : "mdi-file-upload-outline"
                          )
                        ),
                      ]),
                      _c("p", { staticClass: "dropzone-message" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("Drag or")))]),
                        _c("a", [_vm._v(" " + _vm._s(_vm.$t("click")) + " ")]),
                        _c("span", [_vm._v(_vm._s(_vm.$t("for file upload")))]),
                      ]),
                      _c("p", { staticClass: "dropzone-subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Supported file extensions are: .xls, .xlsx")
                          )
                        ),
                      ]),
                    ],
                    1
                  )
                : _c(
                    "div",
                    [
                      _c("v-icon", [_vm._v("mdi-file-document-alert-outline")]),
                      _c("p", { staticClass: "dropzone-message" }, [
                        _vm._v(_vm._s(_vm.$t("File is not supported"))),
                      ]),
                      _c("p", { staticClass: "dropzone-subtitle" }, [
                        _c("span", [_vm._v(_vm._s(_vm.$t("Please")))]),
                        _c("a", [
                          _vm._v(" " + _vm._s(_vm.$t("try again")) + " "),
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t("with a supported file format"))
                          ),
                        ]),
                      ]),
                      _c("p", { staticClass: "dropzone-subtitle" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t("Supported file extensions are: .xls, .xlsx")
                          )
                        ),
                      ]),
                    ],
                    1
                  ),
              _vm.loading
                ? _c("v-progress-linear", { attrs: { indeterminate: "" } })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]),
    _c(
      "div",
      { staticClass: "d-flex align-center mt-2", attrs: { align: "center" } },
      [
        _c(
          "div",
          { staticClass: "mb-3 d-flex align-center" },
          [
            _c("v-icon", [_vm._v(" mdi-paperclip ")]),
            _c("div", { staticClass: "mx-2" }, [
              _vm._v(_vm._s(_vm.$t("Uploaded file:"))),
            ]),
          ],
          1
        ),
        _c("cs-text-field", {
          attrs: {
            rules: _vm.rules,
            dense: "",
            "single-line": "",
            value: _vm.fileName,
            readonly: "",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }