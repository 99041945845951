
import {
  OnStockModel,
  SummaryType,
  WarehouseObjectMatch,
  WarehouseOptimization,
} from '@/modules/graphql/graphql.types'
import {
  buildRemainingItemsTree,
  exportWarehouseOptimization,
  isSectionNameEqual,
  onStockStore,
  removeSelectionAndIsolation,
  setFetchedWarehouseOptimization,
  setSelectionAndIsolation,
  supplyOptColor,
  warehouseOptColor,
  warehouseOptimizationItems,
} from '@/modules/onstock'
import { setWarehouseInstanceColors } from '@/modules/warehouse/warehouse.store'
import {
  getCompareStateBasedOnFadingLevel,
  ModelStateNumber,
  modelViewerStore,
  setInstanceColor,
} from '@/store/modelViewer.store'
import { CsBtn, CsFlex, CsRadio, CsRadioGroup, CsSelect, CsWarningDialog } from '@consteel/csuetify'
import { Model } from '@consteel/smadsteelloader'
import { CacheContainer, Get, GetAll, Portion, StructuralMember } from '@consteel/storm'
import { Color3 } from '@consteel/straw'
import Vue from 'vue'
import WarehouseOptimizationDialog from '../Dialogs/WarehouseOptimizationDialog/WarehouseOptimizationDialog.vue'
import EmptyWarehouseOptimization from '../EmptyWarehouseOptimization.vue'
import ModelStateChip from './ModelStateChip.vue'
import WarehouseOptimizationSelectTree, {
  WarehouseOptimizationTreeNode,
} from './WarehouseOptimizationSelectTree.vue'

export default Vue.extend({
  name: 'WarehouseOptimizationTab',
  components: {
    CsFlex,
    CsBtn,
    WarehouseOptimizationSelectTree,
    WarehouseOptimizationDialog,
    CsRadio,
    CsRadioGroup,
    CsSelect,
    ModelStateChip,
    EmptyWarehouseOptimization,
    CsWarningDialog,
  },
  data() {
    return {
      cache: new CacheContainer(),
      secondaryCache: new CacheContainer(),
      filter: SummaryType.Section as SummaryType,
      loading: false,
      warehouseOptimizationDialog: false,
      selectedPortionId: null as string | null,
      showDeleteObjectMatchNodeDialog: false,
      deletingObjectMatchNode: null as WarehouseOptimizationTreeNode | null,
      doNotAskAgainDelete: false,
    }
  },
  async mounted() {
    await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    this.setWarehouseOptInstanceColors()
  },
  computed: {
    warehouseOptColor: warehouseOptColor,
    warehouseStartBtnDisabled(): boolean {
      return this.loading || !!this.$route.params.historyId
    },
    portionItems(): Array<{ text: string | undefined; value: string | null | undefined }> {
      if (!this.warehouseOptimization) return []

      return [
        ...this.warehouseOptimization.warehouseOptimizationPortions.map((portion) => ({
          text: portion.portionName,
          value: portion.portionSmadsteelId,
        })),
        { text: this.$t('Full model') as string, value: null },
      ]
    },
    activeModelState(): ModelStateNumber {
      return getCompareStateBasedOnFadingLevel()
    },
    activeModelNumber(): string {
      return getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
        ? modelViewerStore.secondaryModel?.modelNumber || ''
        : modelViewerStore.model.modelNumber || ''
    },
    isEmpty(): boolean {
      return !onStockStore.warehouseOptimization && !onStockStore.warehouseOptimizationSecondary
    },
    isCompare(): boolean {
      return modelViewerStore.activeMainTab == 1
    },
    SectionFilterValue(): SummaryType {
      return SummaryType.Section
    },
    MaterialFilterValue(): SummaryType {
      return SummaryType.Material
    },
    warehouseOptimizationDate(): string {
      let date = new Date()

      if (this.isCompare && this.warehouseOptimization && this.warehouseOptimizationSecondary) {
        date =
          getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
            ? this.warehouseOptimization.creationDate
            : this.warehouseOptimizationSecondary.creationDate
      } else if (this.warehouseOptimization) {
        date = this.warehouseOptimization.creationDate
      }

      return Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    },
    warehouseOptimizationPriorityData(): Record<string, string> {
      return {
        LENGTH: this.$t('Length') as string,
        INVENTORY_DATE: this.$t('Date') as string,
        WEIGHT: this.$t('Weight') as string,
      }
    },
    warehouseOptimizationMatchingData(): Record<string, string> {
      return {
        FAST_APPROXIMATION: this.$t('Approximate') as string,
        SLOW_ACCURATE: this.$t('Accurate') as string,
      }
    },
    warehouseOptimizationMainPriority(): string {
      return this.warehouseOptimization?.mainPriority
        ? this.warehouseOptimizationPriorityData[this.warehouseOptimization?.mainPriority]
        : ''
    },
    warehouseOptimizationSecondaryPriority(): string {
      return this.warehouseOptimization?.secondaryPriority
        ? this.warehouseOptimizationPriorityData[this.warehouseOptimization?.secondaryPriority]
        : ''
    },
    warehouseOptimizationMatchingMethod(): string {
      return this.warehouseOptimization?.matchingMethod
        ? this.warehouseOptimizationMatchingData[this.warehouseOptimization?.matchingMethod]
        : ''
    },
    modelFile(): Model | undefined | null {
      return modelViewerStore.model.rawSmadsteelModel
    },
    onStockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    modelFileSecondary(): Model | undefined | null {
      return modelViewerStore.secondaryModel.rawSmadsteelModel
    },
    structuralMembers(): StructuralMember[] {
      if (!this.modelFile) {
        console.log(`Model file doesn't exist`)
        return []
      }

      return GetAll<StructuralMember>(this.modelFile, StructuralMember, undefined, this.cache)
    },
    getMembersBasedOnPortionAndWithoutWarehouseOpt(): StructuralMember[] {
      let membersBasedOnPortionAndWithoutWarehouseOpt = this.structuralMembers

      // filter out portion related members
      const smadsteelModel = modelViewerStore.model.rawSmadsteelModel
      if (smadsteelModel && this.selectedPortionId) {
        const portion = Get<Portion>(smadsteelModel, this.selectedPortionId, Portion)

        if (portion) {
          const objectsInPortions = portion.items?.map((item) => item.InstanceGuid)

          if (objectsInPortions) {
            membersBasedOnPortionAndWithoutWarehouseOpt =
              membersBasedOnPortionAndWithoutWarehouseOpt.filter(
                (item) => item.id && objectsInPortions.includes(item.id)
              )
          }
        }
      }

      // filter out portion members which do not belong to warehose opt
      return membersBasedOnPortionAndWithoutWarehouseOpt.filter((structuralMember) => {
        const isMemberInWarehouseOpt = this.getWarehouseOptMatchesBasedOnPortion.find(
          (warehouseMatch) => warehouseMatch.smadsteelId == structuralMember.id
        )

        return !isMemberInWarehouseOpt
      })
    },
    getWarehouseOptMatchesBasedOnPortion(): WarehouseObjectMatch[] {
      if (!this.warehouseOptimization) return []

      const objectMatches = this.selectedPortionId
        ? this.warehouseOptimization.warehouseOptimizationPortions.find(
            (portion) => portion.portionSmadsteelId == this.selectedPortionId
          )?.warehouseObjectMatches
        : this.warehouseOptimization.warehouseObjectMatches

      if (!objectMatches) return []

      return objectMatches
    },
    warehouseOptItems(): WarehouseOptimizationTreeNode[] {
      return warehouseOptimizationItems(
        this.warehouseOptimization,
        this.warehouseOptimizationSecondary,
        this.modelFile || null,
        this.modelFileSecondary || null,
        this.filter,
        this.isCompare,
        this.selectedPortionId
      )
    },
    warehouseOptTree(): WarehouseOptimizationTreeNode[] {
      // remove sections that do not have children
      let filteredSections: WarehouseOptimizationTreeNode[] = []
      this.warehouseOptItems.forEach((section: WarehouseOptimizationTreeNode) => {
        const isSectionHasChild = section.children && section.children.length > 0

        if (isSectionHasChild) filteredSections.push(section)
      })

      return [
        {
          id: 'warehouseOptItems',
          name: this.$t('From warehouse'),
          value: 0,
          hideProgress: true,
          children: filteredSections,
          afterIcon: { name: 'mdi-square', color: warehouseOptColor(), size: 16 },
        },
      ] as WarehouseOptimizationTreeNode[]
    },
    remainingItemsTree(): WarehouseOptimizationTreeNode[] {
      const remainingMembers = this.getMembersBasedOnPortionAndWithoutWarehouseOpt.slice()
      const remainingItemsMap = buildRemainingItemsTree(remainingMembers, this.filter)

      let remainingItemsTree: WarehouseOptimizationTreeNode[] = []
      remainingItemsMap.forEach((value: WarehouseOptimizationTreeNode, key: string) => {
        const isSectionInWarehouse = this.warehouseOptItems.find((warehouseItem) =>
          isSectionNameEqual(warehouseItem.name, key)
        )

        if (!isSectionInWarehouse) {
          remainingItemsTree.push({
            ...value,
            afterIcon: { name: 'mdi-cart-arrow-right', color: supplyOptColor(), size: 18 },
          })
        } else remainingItemsTree.push(value)
      })

      return [
        {
          id: 'remainingItems',
          name: this.$t('Remainder'),
          value: 0,
          hideProgress: true,
          children: remainingItemsTree,
        },
      ] as WarehouseOptimizationTreeNode[]
    },
    warehouseOptimization(): WarehouseOptimization | null {
      return onStockStore.warehouseOptimization
    },
    warehouseOptimizationSecondary(): WarehouseOptimization | null {
      return onStockStore.warehouseOptimizationSecondary
    },
    selectedIds(): string[] {
      return modelViewerStore.selectedIds
    },
  },
  watch: {
    async warehouseOptimization() {
      this.selectedPortionId =
        this.warehouseOptimization?.warehouseOptimizationPortions[0]?.portionSmadsteelId || null
      this.setWarehouseOptInstanceColors()
    },
    async modelFile(): Promise<void> {
      this.cache.Clear()
      await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    },
    async onstockModel(): Promise<void> {
      await setFetchedWarehouseOptimization(this.isCompare, this.$route.params.historyId)
    },
    modelFileSecondary(): void {
      this.secondaryCache.Clear()
    },
    selectedPortionId(): void {
      this.setWarehouseOptInstanceColors()
    },
  },
  methods: {
    setWarehouseOptInstanceColors(): void {
      if (this.warehouseOptimization) {
        const instanceColors: { id: string; color: Color3 }[] = [
          ...this.getWarehouseOptMatchesBasedOnPortion.map((warehouseMatch) => {
            return {
              id: warehouseMatch.smadsteelId,
              color: Color3.FromHexString(warehouseOptColor()),
            } as {
              id: string
              color: Color3
            }
          }),
        ]
        setWarehouseInstanceColors([...instanceColors])
        setInstanceColor(...instanceColors)
      }
    },
    async onClickDeleteObjectMatchNode(node: WarehouseOptimizationTreeNode) {
      this.deletingObjectMatchNode = node
      if (this.doNotAskAgainDelete && node.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(node.objectMatchId)
      } else {
        this.showDeleteObjectMatchNodeDialog = true
      }
    },
    async onClickApproveDeleteObjectMatchNode(doNotAskAgain: boolean) {
      this.doNotAskAgainDelete = doNotAskAgain
      this.showDeleteObjectMatchNodeDialog = false
      if (this.deletingObjectMatchNode?.objectMatchId) {
        await this.deletingObjectMatchNode?.deleteMatchHandler?.(
          this.deletingObjectMatchNode.objectMatchId
        )
      }
      this.deletingObjectMatchNode = null
    },
    handleClickShowStartWarehouseOptimization() {
      this.warehouseOptimizationDialog = true
    },
    async handleClickWarehouseOptimizationExport() {
      if (!onStockStore.project) return
      if (!onStockStore.onStockModel) return
      if (!onStockStore.warehouseOptimization) return
      if (this.isCompare && !onStockStore.warehouseOptimizationSecondary) return

      await exportWarehouseOptimization(
        onStockStore.project.id.toString(),
        onStockStore.onStockModel.id.toString(),
        this.isCompare &&
          !!onStockStore.warehouseOptimizationSecondary &&
          getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
          ? onStockStore.warehouseOptimizationSecondary.id.toString()
          : onStockStore.warehouseOptimization.id.toString()
      )
    },
    select: setSelectionAndIsolation,
    remove: removeSelectionAndIsolation,
  },
})
