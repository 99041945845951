var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        title: _vm.$t("Warehouse optimization"),
        persistent: "",
        maxWidth: _vm.maxWidth,
        width: _vm.maxWidth,
      },
      on: { close: _vm.dialogClose },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function () {
            return [
              _c(
                "cs-flex",
                {
                  staticClass: "warehouse-optimization-dialog-action-btns mx-6",
                  attrs: { justify: "flex-end", gap: "1.5rem" },
                },
                [
                  _c(
                    "cs-btn",
                    {
                      attrs: { outlined: "" },
                      on: { click: _vm.stepBackBtnClicked },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Back")) + " ")]
                  ),
                  _vm.step === _vm.lastStep
                    ? _c(
                        "cs-btn",
                        {
                          attrs: { disabled: !_vm.valid, loading: _vm.loading },
                          on: { click: _vm.submit },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Optimization")) + " ")]
                      )
                    : _c(
                        "cs-btn",
                        {
                          attrs: { disabled: !_vm.valid },
                          on: { click: _vm.stepForwardBtnClicked },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("Next")) + " ")]
                      ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", absolute: "", top: "" },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "warehouse-optimization-dialog mx-6" },
        [
          _c(
            "v-stepper",
            {
              staticClass: "elevation-0 warehouse-optimization-dialog-stepper",
              attrs: { "alt-labels": "", color: "inherit" },
              model: {
                value: _vm.step,
                callback: function ($$v) {
                  _vm.step = $$v
                },
                expression: "step",
              },
            },
            [
              _c(
                "v-stepper-header",
                { staticClass: "elevation-0" },
                [
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 1, step: "1" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Portions"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 2, step: "2" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Warehouse"))),
                        ]),
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("upload"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 3, step: "3" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Prioritization"))),
                        ]),
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("setting"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("v-divider"),
                  _c(
                    "v-stepper-step",
                    { attrs: { complete: _vm.step > 4, step: "4" } },
                    [
                      _c("cs-flex", { attrs: { vertical: "" } }, [
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("Rules"))),
                        ]),
                        _c("div", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("setting"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-stepper-items",
                [
                  _c(
                    "v-form",
                    {
                      ref: "warehouseOptimizationForm",
                      attrs: { "lazy-validation": "" },
                      model: {
                        value: _vm.valid,
                        callback: function ($$v) {
                          _vm.valid = $$v
                        },
                        expression: "valid",
                      },
                    },
                    [
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "1" } },
                        [
                          _c("optimization-dialog-portion-section", {
                            attrs: { checkFullModel: _vm.checkFullModel },
                            on: {
                              onCheckFullModelChange: function ($event) {
                                _vm.checkFullModel = $event
                              },
                            },
                            model: {
                              value: _vm.selectedPortions,
                              callback: function ($$v) {
                                _vm.selectedPortions = $$v
                              },
                              expression: "selectedPortions",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("v-stepper-content", { attrs: { step: "2" } }, [
                        _vm.step === 2
                          ? _c("div", [
                              _c("div", { staticClass: "mb-2" }, [
                                _vm._v(_vm._s(_vm.$t("Uploading warehouse"))),
                              ]),
                              _c(
                                "div",
                                [
                                  _c("drop-zone", {
                                    ref: "dropZoneRef",
                                    attrs: { required: "" },
                                    model: {
                                      value: _vm.files,
                                      callback: function ($$v) {
                                        _vm.files = $$v
                                      },
                                      expression: "files",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _c("v-stepper-content", { attrs: { step: "3" } }, [
                        _vm.step === 3
                          ? _c(
                              "div",
                              [
                                _c(
                                  "cs-flex",
                                  { attrs: { justify: "space-between" } },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Prioritizing warehouse items")
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "warehouse-optimization-dialog-stepper-priority-info-svg",
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInfoBtnClick(
                                                                _vm
                                                                  .WarehouseOptimizationDialogPopUpRefs
                                                                  .Priority
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_c("info-svg")],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          766996252
                                        ),
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Open documentation")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "cs-grid",
                                  {
                                    staticClass: "mt-4",
                                    attrs: {
                                      "fill-width": "",
                                      cols: 2,
                                      rows: 1,
                                      equalCols: "",
                                      columnGap: "3rem",
                                    },
                                  },
                                  [
                                    _c(
                                      "cs-grid",
                                      {
                                        attrs: {
                                          "fill-width": "",
                                          cols: 2,
                                          rows: 2,
                                          equalCols: "",
                                          justify: "start",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Primary priority") + ":"
                                            )
                                          ),
                                        ]),
                                        _c("cs-select", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: _vm.$t("Choose..."),
                                            inputWidth: 200,
                                            rules: _vm.priorizationRules,
                                            items: _vm.priorityItems,
                                          },
                                          model: {
                                            value: _vm.mainPriority,
                                            callback: function ($$v) {
                                              _vm.mainPriority = $$v
                                            },
                                            expression: "mainPriority",
                                          },
                                        }),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Secondary priority") + ":"
                                            )
                                          ),
                                        ]),
                                        _c("cs-select", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: _vm.$t("Choose..."),
                                            inputWidth: 200,
                                            rules: _vm.priorizationRules,
                                            items: _vm.priorityItems,
                                          },
                                          model: {
                                            value: _vm.secondaryPriority,
                                            callback: function ($$v) {
                                              _vm.secondaryPriority = $$v
                                            },
                                            expression: "secondaryPriority",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "cs-grid",
                                      {
                                        attrs: {
                                          "fill-width": "",
                                          cols: 2,
                                          rows: 2,
                                          equalCols: "",
                                          justify: "start",
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Sorting") + ":")
                                          ),
                                        ]),
                                        _c("cs-select", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: _vm.$t("Choose..."),
                                            inputWidth: 200,
                                            rules: _vm.priorityOrderRules,
                                            items: _vm.priorityOrders,
                                          },
                                          model: {
                                            value: _vm.mainPriorityOrder,
                                            callback: function ($$v) {
                                              _vm.mainPriorityOrder = $$v
                                            },
                                            expression: "mainPriorityOrder",
                                          },
                                        }),
                                        _c("div", [
                                          _vm._v(
                                            _vm._s(_vm.$t("Sorting") + ":")
                                          ),
                                        ]),
                                        _c("cs-select", {
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: _vm.$t("Choose..."),
                                            inputWidth: 200,
                                            rules: _vm.priorityOrderRules,
                                            items: _vm.priorityOrders,
                                          },
                                          model: {
                                            value: _vm.secondaryPriorityOrder,
                                            callback: function ($$v) {
                                              _vm.secondaryPriorityOrder = $$v
                                            },
                                            expression:
                                              "secondaryPriorityOrder",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "cs-flex",
                                  {
                                    staticClass:
                                      "warehouse-optimization-dialog-stepper-priority-warehouse-optimization-mode-selector-cont",
                                    attrs: { justify: "space-between" },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Optimization mode") + ":"
                                        )
                                      ),
                                    ]),
                                    _c(
                                      "cs-flex",
                                      { attrs: { gap: "2rem" } },
                                      [
                                        _c("cs-select", {
                                          staticClass:
                                            "warehouse-optimization-dialog-stepper-priority-warehouse-optimization-mode-selector",
                                          attrs: {
                                            "hide-details": "",
                                            dense: "",
                                            placeholder: _vm.$t("Choose..."),
                                            inputWidth: 200,
                                            rules: _vm.priorizationRules,
                                            items: _vm.methodItems,
                                          },
                                          model: {
                                            value: _vm.method,
                                            callback: function ($$v) {
                                              _vm.method = $$v
                                            },
                                            expression: "method",
                                          },
                                        }),
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "warehouse-optimization-dialog-stepper-priority-info-svg",
                                                              attrs: {
                                                                icon: "",
                                                                small: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.handleInfoBtnClick(
                                                                      _vm
                                                                        .WarehouseOptimizationDialogPopUpRefs
                                                                        .WarehouseOptimizationMode
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_c("info-svg")],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3686652137
                                            ),
                                          },
                                          [
                                            _c("p", { staticClass: "mb-0" }, [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("Open documentation")
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "cs-flex",
                                  {
                                    staticClass: "mt-4",
                                    attrs: { justify: "space-between" },
                                  },
                                  [
                                    _c("cs-unit-field", {
                                      attrs: {
                                        title:
                                          _vm.$t("Tolerance") + " (0-100%):",
                                        rules: _vm.toleranceRules,
                                        unit: "mm",
                                        precision: 0,
                                        maxWidth: "110px",
                                      },
                                      model: {
                                        value: _vm.tolerance,
                                        callback: function ($$v) {
                                          _vm.tolerance = $$v
                                        },
                                        expression: "tolerance",
                                      },
                                    }),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "warehouse-optimization-dialog-stepper-priority-info-svg",
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInfoBtnClick(
                                                                _vm
                                                                  .WarehouseOptimizationDialogPopUpRefs
                                                                  .Tolerance
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_c("info-svg")],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1585542855
                                        ),
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Open documentation")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "cs-flex",
                                  {
                                    staticClass: "mt-6",
                                    attrs: { justify: "space-between" },
                                  },
                                  [
                                    _c("v-checkbox", {
                                      staticClass: "mt-0 pt-0",
                                      attrs: {
                                        "hide-details": "",
                                        label: _vm.$t(
                                          "Prioritizing exact match"
                                        ),
                                      },
                                      model: {
                                        value: _vm.preferExactMatchesChecked,
                                        callback: function ($$v) {
                                          _vm.preferExactMatchesChecked = $$v
                                        },
                                        expression: "preferExactMatchesChecked",
                                      },
                                    }),
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: { bottom: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass:
                                                            "warehouse-optimization-dialog-stepper-priority-info-svg",
                                                          attrs: {
                                                            icon: "",
                                                            small: "",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.handleInfoBtnClick(
                                                                _vm
                                                                  .WarehouseOptimizationDialogPopUpRefs
                                                                  .Match
                                                              )
                                                            },
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [_c("info-svg")],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2401145437
                                        ),
                                      },
                                      [
                                        _c("p", { staticClass: "mb-0" }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("Open documentation")
                                              ) +
                                              " "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                      _c(
                        "v-stepper-content",
                        { attrs: { step: "4" } },
                        [
                          _vm.step === 4
                            ? _c(
                                "warehouse-optimization-dialog-rules-manager",
                                {
                                  ref: "warehouseOptimizationDialogRulesManager",
                                  attrs: {
                                    preferExactMatchesChecked:
                                      _vm.preferExactMatchesChecked,
                                  },
                                  on: {
                                    managerRuleTableItemsUpdated:
                                      _vm.managerRuleTableItemsUpdated,
                                    closeOrOpenWarehouseOptimizationDialog:
                                      _vm.closeOrOpenWarehouseOptimizationDialog,
                                    infoBtnClicked: function ($event) {
                                      return _vm.handleInfoBtnClick(
                                        _vm.WarehouseOptimizationDialogPopUpRefs
                                          .Match
                                      )
                                    },
                                  },
                                }
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("optimization-progress-dialog", {
        attrs: {
          currentProgressIndex: _vm.currentWarehouseOptimizationStageIndex,
          progresses: _vm.getWarehouseOptimizationProgresses,
          title: _vm.$t("Running optimization..."),
        },
        model: {
          value: _vm.warehouseOptimizationProgressDialog,
          callback: function ($$v) {
            _vm.warehouseOptimizationProgressDialog = $$v
          },
          expression: "warehouseOptimizationProgressDialog",
        },
      }),
      _c("warehouse-optimization-dialog-pop-up", {
        ref: "warehouseOptimizationDialogPopUp",
        model: {
          value: _vm.warehouseOptimizationDialogPopUp,
          callback: function ($$v) {
            _vm.warehouseOptimizationDialogPopUp = $$v
          },
          expression: "warehouseOptimizationDialogPopUp",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }