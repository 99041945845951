var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "empty-warehouse-optimization",
      attrs: { justify: "center", vertical: "" },
    },
    [
      _c(
        "v-icon",
        {
          staticClass: "mb-7 empty-warehouse-optimization-svg",
          attrs: { size: _vm.iconSize },
        },
        [_vm._v("mdi-sync")]
      ),
      _c("div", { staticClass: "empty-warehouse-optimization-text" }, [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t(
                "Click the Start button to begin the optimization process. The results of the optimization will be displayed on this panel."
              )
            ) +
            " "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }