var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "display-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
      },
    },
    [
      _c(
        "cs-slider",
        {
          staticClass: "px-5 pt-5",
          attrs: {
            checkable: "",
            min: 0,
            max: 2000,
            title: _vm.$t("Loads"),
            infoText: _vm.$t("Adjust the scaling for loads."),
          },
          on: { change: _vm.displayLoads },
          model: {
            value: _vm.loadSlider,
            callback: function ($$v) {
              _vm.loadSlider = _vm._n($$v)
            },
            expression: "loadSlider",
          },
        },
        [
          _c("small-arrow-svg", {
            attrs: { slot: "beforeIcon" },
            slot: "beforeIcon",
          }),
          _c("big-arrow-svg", {
            attrs: { slot: "afterIcon" },
            slot: "afterIcon",
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "cs-slider",
        {
          staticClass: "px-5 pt-3",
          attrs: {
            checkable: "",
            min: 0,
            max: 1000,
            title: _vm.$t("Supports"),
            infoText: _vm.$t("Adjust the scaling for supports."),
          },
          on: { change: _vm.displaySupports },
          model: {
            value: _vm.supportSlider,
            callback: function ($$v) {
              _vm.supportSlider = _vm._n($$v)
            },
            expression: "supportSlider",
          },
        },
        [
          _c("triangle-svg", {
            attrs: { slot: "beforeIcon", scale: 0.5 },
            slot: "beforeIcon",
          }),
          _c("triangle-svg", {
            attrs: { slot: "afterIcon" },
            slot: "afterIcon",
          }),
        ],
        1
      ),
      _c("v-divider"),
      _c(
        "cs-slider",
        {
          staticClass: "px-5 pt-3",
          attrs: {
            checkable: "",
            min: 0,
            max: 20,
            title: _vm.$t("Labels"),
            infoText: _vm.$t("Adjust the scaling for labels."),
          },
          on: {
            change: function ($event) {
              return _vm.$emit("toggleLabels", $event)
            },
          },
          model: {
            value: _vm.labelSlider,
            callback: function ($$v) {
              _vm.labelSlider = _vm._n($$v)
            },
            expression: "labelSlider",
          },
        },
        [
          _c("letter-svg", {
            attrs: { slot: "beforeIcon", scale: 0.75 },
            slot: "beforeIcon",
          }),
          _c("letter-svg", { attrs: { slot: "afterIcon" }, slot: "afterIcon" }),
        ],
        1
      ),
      _c("v-checkbox", {
        staticClass: "px-5",
        attrs: { "input-value": true, dense: "" },
        on: {
          change: function ($event) {
            return _vm.$emit("setLabelShowAddLabelOption", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "checkbox-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t('Show "Add label" option when selecting objects')
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-divider"),
      _c(
        "cs-slider",
        {
          staticClass: "px-5 pt-3",
          attrs: {
            checkable: "",
            min: 0,
            max: 20,
            title: _vm.$t("Comments"),
            infoText: _vm.$t("Adjust the scaling for comments."),
          },
          on: {
            change: function ($event) {
              return _vm.$emit("toggleComments", $event)
            },
          },
          model: {
            value: _vm.commentSlider,
            callback: function ($$v) {
              _vm.commentSlider = _vm._n($$v)
            },
            expression: "commentSlider",
          },
        },
        [
          _c("chat-svg", {
            attrs: { slot: "beforeIcon", scale: 0.75 },
            slot: "beforeIcon",
          }),
          _c("chat-svg", { attrs: { slot: "afterIcon" }, slot: "afterIcon" }),
        ],
        1
      ),
      _c("v-checkbox", {
        staticClass: "px-5",
        attrs: { "input-value": true, dense: "" },
        on: {
          change: function ($event) {
            return _vm.$emit("setCommentShowCreateCommentOption", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "checkbox-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          'Show "Create comment" option when selecting objects'
                        )
                      ) +
                      " "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-checkbox", {
        staticClass: "px-5",
        attrs: { "input-value": true, dense: "" },
        on: {
          change: function ($event) {
            return _vm.$emit("setCommentFocusOnObjectOption", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "checkbox-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Focus on object while selecting comments"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-divider"),
      _c("div", { staticClass: "px-5 pt-3" }, [
        _vm._v(_vm._s(_vm.$t("Selection"))),
      ]),
      _c("v-checkbox", {
        staticClass: "px-5",
        attrs: {
          "input-value": true,
          disabled: _vm.unselectedMembersToTransparentOptionDisabled,
          dense: "",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("setUnselectedMembersToTransparentOption", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "checkbox-label" }, [
                  _vm._v(
                    _vm._s(_vm.$t("Set unselected members to transparent"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-checkbox", {
        staticClass: "px-5",
        attrs: { "input-value": true, dense: "" },
        on: {
          change: function ($event) {
            return _vm.$emit("setMoveCameraToSelectionOption", $event)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "label",
            fn: function () {
              return [
                _c("span", { staticClass: "checkbox-label" }, [
                  _vm._v(_vm._s(_vm.$t("Move camera to the selected members"))),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-divider"),
      _c("clip-plane"),
      _c("v-divider"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }