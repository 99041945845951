var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showManagerRuleEditorDialog
    ? _c("manager-rule-editor-dialog", {
        attrs: {
          defaultManagerRuleDialogData: _vm.defaultManagerRuleDialogData,
        },
        model: {
          value: _vm.showManagerRuleEditorDialog,
          callback: function ($$v) {
            _vm.showManagerRuleEditorDialog = $$v
          },
          expression: "showManagerRuleEditorDialog",
        },
      })
    : _vm.showManagerRuleSummaryDialog
    ? _c("manager-rules-summary-dialog", {
        model: {
          value: _vm.showManagerRuleSummaryDialog,
          callback: function ($$v) {
            _vm.showManagerRuleSummaryDialog = $$v
          },
          expression: "showManagerRuleSummaryDialog",
        },
      })
    : _c(
        "div",
        { staticClass: "warehouse-optimization-dialog-rules-manager" },
        [
          _c(
            "cs-flex",
            { attrs: { justify: "space-between" } },
            [
              _c("div", [
                _vm._v(_vm._s(_vm.$t("Applying warehouse optimization rules"))),
              ]),
              _c(
                "cs-btn",
                {
                  staticClass:
                    "warehouse-optimization-dialog-rules-manager-get-all-rules",
                  attrs: { text: "" },
                  on: { click: _vm.handleGetAllManagerRulesBtnClick },
                },
                [_vm._v(" " + _vm._s(_vm.$t("All rules")) + " ")]
              ),
            ],
            1
          ),
          _c("v-data-table", {
            staticClass:
              "warehouse-optimization-dialog-rules-manager-manager-rule-table mt-1",
            attrs: {
              "filter-mode": "union",
              height: 200,
              headers: _vm.managerRuleHeaders,
              items: _vm.managerRuleTableItems,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.rule`,
                  fn: function ({ item }) {
                    return [
                      _c("cs-select", {
                        attrs: {
                          dense: "",
                          "full-width": "",
                          items: _vm.getManagerRuleSelectorItems(item),
                          "item-text": "rule",
                          placeholder: _vm.$t("Choose rule"),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.ruleSelectorChanged(item)
                          },
                        },
                        model: {
                          value: item.rule,
                          callback: function ($$v) {
                            _vm.$set(item, "rule", $$v)
                          },
                          expression: "item.rule",
                        },
                      }),
                    ]
                  },
                },
                {
                  key: `item.actions`,
                  fn: function ({ item }) {
                    return [
                      item.rule !== ""
                        ? _c(
                            "cs-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickOpenRuleAsReadOnly(
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-eye")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickEditRule(item.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickDeleteRule(item.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "cs-flex",
                            { attrs: { justify: "flex-end" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickDeleteRule(item.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ]
                  },
                },
                {
                  key: `footer.prepend`,
                  fn: function () {
                    return [
                      _c(
                        "cs-btn",
                        { on: { click: _vm.handleClickCreateNewRule } },
                        [
                          _c("v-icon", [_vm._v("mdi-plus")]),
                          _vm._v(_vm._s(_vm.$t("Create new"))),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: `footer`,
                  fn: function () {
                    return [
                      _c(
                        "cs-btn",
                        {
                          attrs: {
                            outlined: "",
                            width: "100%",
                            disabled: _vm.isAllManagerRulesSelected,
                          },
                          on: { click: _vm.handleAddRuleBtnClick },
                        },
                        [_c("v-icon", [_vm._v("mdi-plus")])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          }),
          _c("div", { staticClass: "mt-8" }, [
            _vm._v(_vm._s(_vm.$t("Engineer settings"))),
          ]),
          _c("v-data-table", {
            staticClass: "mt-1",
            attrs: {
              "filter-mode": "union",
              height: 200,
              headers: _vm.engineerRuleHeaders,
              items: _vm.engineerRuleTableItems,
            },
          }),
          _vm.preferExactMatchesChecked
            ? _c(
                "cs-flex",
                { staticClass: "my-5", attrs: { justify: "space-between" } },
                [
                  _c("cs-flex", { attrs: { gap: "2rem" } }, [
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("Prioritize exact matches"))),
                    ]),
                    _c("div", [_vm._v(_vm._s(_vm.$t("Section and material")))]),
                  ]),
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass:
                                          "warehouse-optimization-dialog-rules-manager-info-svg",
                                        attrs: { icon: "", small: "" },
                                        on: { click: _vm.handleInfoBtnClick },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("info-svg")],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        305089995
                      ),
                    },
                    [
                      _c("p", { staticClass: "mb-0" }, [
                        _vm._v(
                          " " + _vm._s(_vm.$t("Open documentation")) + " "
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }