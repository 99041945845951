var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-flex",
    {
      staticClass: "warehouse-optimization-tab",
      attrs: {
        "fill-height": "",
        "fill-width": "",
        vertical: "",
        justify: "flex-start",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex flex-row pt-7 px-4 pb-5" },
        [
          _c("div", [
            _c(
              "div",
              { staticClass: "warehouse-optimization-radio-group-title" },
              [
                _vm._v(" " + _vm._s(_vm.$t("Warehouse")) + " "),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", "max-width": "350px" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-icon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: { size: "16" },
                                  },
                                  "v-icon",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v("mdi-information-outline")]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("Start the optimization process."))),
                    ]),
                  ]
                ),
              ],
              1
            ),
            !_vm.warehouseLoading && _vm.isEmpty
              ? _c("div", { staticClass: "empty-text" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "You have not started an optimization process yet."
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.isCompare
            ? _c(
                "model-state-chip",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    compare: _vm.isCompare,
                    compareState: _vm.activeModelState,
                  },
                },
                [_vm._v(_vm._s(_vm.activeModelNumber))]
              )
            : _c(
                "cs-btn",
                {
                  staticClass: "ml-auto",
                  attrs: {
                    elevation: "1",
                    small: "",
                    disabled: _vm.warehouseStartBtnDisabled,
                    loading: _vm.warehouseLoading,
                  },
                  on: { click: _vm.handleClickShowStartWarehouseOptimization },
                },
                [
                  _c("v-icon", [_vm._v("mdi-sync")]),
                  _vm._v(" " + _vm._s(_vm.$t("Start"))),
                ],
                1
              ),
        ],
        1
      ),
      !_vm.warehouseLoading
        ? [
            !_vm.isEmpty
              ? _c(
                  "div",
                  [
                    _c("cs-select", {
                      staticClass: "pa-4",
                      attrs: {
                        outlined: "",
                        dense: "",
                        autofocus: "",
                        "hide-details": "",
                        "append-icon": "mdi-chevron-down",
                        items: _vm.portionItems,
                      },
                      model: {
                        value: _vm.selectedPortionId,
                        callback: function ($$v) {
                          _vm.selectedPortionId = $$v
                        },
                        expression: "selectedPortionId",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _c(
            "cs-flex",
            { staticClass: "pa-5", attrs: { vertical: "", "fill-width": "" } },
            [_c("v-skeleton-loader", { attrs: { type: "text" } })],
            1
          ),
      _c("v-divider"),
      _c("warehouse-optimization-dialog", {
        model: {
          value: _vm.warehouseOptimizationDialog,
          callback: function ($$v) {
            _vm.warehouseOptimizationDialog = $$v
          },
          expression: "warehouseOptimizationDialog",
        },
      }),
      !_vm.warehouseLoading
        ? [
            !_vm.isEmpty
              ? [
                  _c(
                    "div",
                    { staticClass: "d-flex px-4 py-4" },
                    [
                      _c("div", { staticClass: "flex-grow-1" }, [
                        _vm._v(_vm._s(_vm.$t("Results"))),
                      ]),
                      _c(
                        "cs-flex",
                        { staticClass: "warehouse-optimization-date" },
                        [_vm._v(_vm._s(_vm.warehouseOptimizationDate))]
                      ),
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "", "max-width": "350px" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "v-icon",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "ml-2",
                                            attrs: { size: "16" },
                                          },
                                          "v-icon",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [_vm._v("mdi-information-outline")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3670940073
                          ),
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row pa-1 pb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Optimization algorithm")) +
                                  ": " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.warehouseOptimizationMatchingMethod
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row pa-1 pb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Primary priority")) +
                                  ": " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.warehouseOptimizationMainPriority
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row pa-1 pb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Secondary priority")) +
                                  ": " +
                                  _vm._s(
                                    _vm.$t(
                                      _vm.warehouseOptimizationSecondaryPriority
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row pa-1 pb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Tolerance")) +
                                  ": " +
                                  _vm._s(
                                    Number(
                                      _vm.warehouseOptimization?.tolerance || 0
                                    )
                                  ) +
                                  "mm "
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row pa-1 pb-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("Warehouse catalog file")) +
                                  ": " +
                                  _vm._s(
                                    _vm.warehouseOptimization
                                      ?.warehouseCatalogFileName
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "cs-btn",
                        {
                          attrs: { icon: "", small: "" },
                          on: {
                            click: _vm.handleClickWarehouseOptimizationExport,
                          },
                        },
                        [
                          _c("v-icon", { attrs: { size: "16" } }, [
                            _vm._v("mdi-download"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "cs-radio-group",
                    {
                      attrs: { row: "" },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    },
                    [
                      _c(
                        "cs-flex",
                        {
                          staticClass: "radio-container px-5",
                          attrs: {
                            "fill-width": "",
                            justify: "flex-start",
                            gap: "3rem",
                          },
                        },
                        [
                          _c("cs-radio", {
                            attrs: {
                              label: _vm.$t("By section"),
                              value: _vm.SectionFilterValue,
                            },
                          }),
                          _c("cs-radio", {
                            attrs: {
                              label: _vm.$t("By material"),
                              value: _vm.MaterialFilterValue,
                              disabled: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("warehouse-optimization-select-tree", {
                    attrs: {
                      items: _vm.warehouseOptTree,
                      selectedIds: _vm.selectedIds,
                      selectedColor: _vm.warehouseOptColor,
                    },
                    on: {
                      select: _vm.select,
                      remove: _vm.remove,
                      delete: _vm.onClickDeleteObjectMatchNode,
                    },
                  }),
                  _c("v-divider"),
                  _c("warehouse-optimization-select-tree", {
                    attrs: {
                      items: _vm.remainingItemsTree,
                      selectedIds: _vm.selectedIds,
                    },
                    on: { select: _vm.select, remove: _vm.remove },
                  }),
                ]
              : _c("empty-warehouse-optimization", {
                  staticClass: "px-4 pt-8",
                }),
          ]
        : _c(
            "cs-flex",
            { staticClass: "pa-5", attrs: { vertical: "", "fill-width": "" } },
            [
              _c("v-skeleton-loader", {
                staticClass: "pb-8",
                attrs: { type: "heading" },
              }),
              _c("v-skeleton-loader", {
                staticClass: "pb-5",
                attrs: { type: "sentences" },
              }),
              _c("v-skeleton-loader", { attrs: { type: "paragraph" } }),
            ],
            1
          ),
      _c("cs-warning-dialog", {
        attrs: {
          doNotAskAgain: "",
          twoButton: "",
          submitText: _vm.$t("Delete"),
          width: "380",
          header: "",
          headerText: _vm.$t("Delete"),
          title: _vm.$t("Delete warehouse optimization"),
          description: _vm.$t(
            "Are you sure you want to delete?  This action is irreversible."
          ),
        },
        on: { submit: _vm.onClickApproveDeleteObjectMatchNode },
        model: {
          value: _vm.showDeleteObjectMatchNodeDialog,
          callback: function ($$v) {
            _vm.showDeleteObjectMatchNodeDialog = $$v
          },
          expression: "showDeleteObjectMatchNodeDialog",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }