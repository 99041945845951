import { Color3 } from '@consteel/straw'
import Vue from 'vue'

const state = Vue.observable({
  warehouseInstanceColors: [] as { id: string; color: Color3 }[],
})

export const setWarehouseInstanceColors = (value: { id: string; color: Color3 }[]): void => {
  state.warehouseInstanceColors = value
}

export const warehouseStore = state as Readonly<typeof state>
